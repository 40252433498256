import React from 'react';
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './App.css';

const queryClient = new QueryClient();

const FETCH_PEOPLE_URL = 'https://api.nordpolen.net/api/test';

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <header className="App-header">
          <h1>Fetch people</h1>
          <FetchPeople />
        </header>
      </div>
    </QueryClientProvider>
  );
}

function FetchPeople() {
  const fetchNames = async () => {
    if (!process.env.REACT_APP_API_KEY) {
      // throw new Error('API key not found');
      console.log('API key not found');
      return [];
    }

    const response = await fetch(FETCH_PEOPLE_URL, {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
      credentials: 'include', // Include credentials if needed
    });
    
    if (!response.ok) {
      console.log('API request failed');
      return [];
    }

    return response.json();
  }

  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: ['people'],
    queryFn: fetchNames,
    staleTime: 5000,
    enabled: false
  });

  return (
    <div>
      <button
        onClick={() => refetch()}
        disabled={isLoading}
      >
        {isLoading ? 'Loading...' : 'Fetch data'}
      </button>
      {isError && <div>Error fetching data</div>}
      {data && <PeopleList people={data} />}
    </div>
  )
};

function PeopleList({ people }) {
  console.log('people', people);
  return (
    <ul>
      {people.map(person => (
        <li key={person.name}>{person.name}</li>
      ))}
    </ul>
  );
}

export default App;
